import React from "react";
import logo from "./../assets/logo192.png";
interface Props {
  brokerId?: string | null;
}

export const ErrorView: React.FC<Props> = ({ brokerId }) => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 600,
    marginLeft: 15,
    marginRight: 15,
  };

  const contentStyle = {
    maxWidth: 400,
    width: "100%",
  };

  const buttonStyle = {
    backgroundColor: "#00CC4F",
    borderRadius: 100,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const headerStyle = {
    marginBottom: 20,
  };

  const paragraphStyle = {
    marginBottom: 100,
  };

  const buttonTextStyle = {
    fontWeight: "bold" as "bold",
  };

  const imgStyle = {
    maxWidth: 100,
  };

  const handleRedirectNavigation = () => {
    if (brokerId) {
      window.location.href = `https://app.streetbeat.com/onboarding/brokerage-connect/${brokerId}`;
    }
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <img style={imgStyle} src={logo} />
        <h2 style={headerStyle}>Brokerage Connection Failed</h2>
        <p style={paragraphStyle}>
          We couldn’t connect your brokerage account. Please return to the
          Streetbeat app and try again.
        </p>
        <div onClick={handleRedirectNavigation} style={buttonStyle}>
          <p style={buttonTextStyle}>Go back to Streetbeat</p>
        </div>
      </div>
    </div>
  );
};
