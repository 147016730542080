import React, { useState, useEffect } from "react";
import { FrontComponent } from "./Front";
import { FrontPayload } from "@front-finance/link";
import axios from "axios";
import { SuccessView } from "./SuccessView";
import { ErrorView } from "./ErrorView";

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (data: string) => void;
    };
  }
}

export const App: React.FC = () => {
  const [iframeLink, setIframeLink] = useState<string | null>(null);
  const [brokerId, setBrokerId] = useState<string | null>(null);

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const iFrameUrl = searchParams.get("iFrameUrl");
    const brokerId = searchParams.get("brokerId");

    setIframeLink(iFrameUrl);
    setBrokerId(brokerId);
  }, []);

  useEffect(() => {
    const popUp = document.getElementById("front-link-popup");

    if (error || success) {
      if (popUp) popUp.style.display = "none";
    }
  }, [error, success]);

  if (success) {
    return <SuccessView brokerId={brokerId} />;
  }

  if (error) {
    return <ErrorView brokerId={brokerId} />;
  }

  return (
    <>
      <FrontComponent
        iframeLink={iframeLink}
        onSuccess={(authData: FrontPayload) => {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(authData));
          } else {
            let backendUrl =
              "https://api.streetbeat.com/api/v1/frontfinance/login";

            const params = new URLSearchParams(window.location.search);
            const envLocal = params.get("env");
            const iFrameUrlLocal = params.get("iFrameUrl");

            if (envLocal === "staging") {
              backendUrl =
                "https://api.staging.streetbeat.com/api/v1/frontfinance/login";
            }

            axios
              .post(backendUrl, { ...authData, iFrameUrl: iFrameUrlLocal })
              .then((response) => {
                setSuccess(true);
              })
              .catch((error) => {
                setError(true);
              });
          }

          setIframeLink(null);
        }}
        onExit={(err) => {
          setError(true);
          setIframeLink(null);
        }}
      />
    </>
  );
};

export default App;
